import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import Button from '_components/button'
import { sendBudgetCounterProposal, SENT_BUDGET_COUNTER_PROPOSAL } from '_modules/budget/actions'
import useFetchCall from '_hooks/use-fetch-call'

import useStyles from './styles'

const SelectProviderDialog = ({
  isOpen,
  onClose,
  serviceOrderId,
  budgetId,
  onSendCounterProposalSuccess,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const onCancel = useCallback(() => {
    const payload = {
      requestedChangeServiceProvider: false,
    }

    dispatch(sendBudgetCounterProposal({ serviceOrderId, budgetId, payload }))
  }, [budgetId, dispatch, serviceOrderId])

  const onSearchForAnotherProvider = useCallback(() => {
    const payload = {
      requestedChangeServiceProvider: true,
    }

    dispatch(sendBudgetCounterProposal({ serviceOrderId, budgetId, payload }))
  }, [budgetId, dispatch, serviceOrderId])

  const [isLoading] = useFetchCall(
    SENT_BUDGET_COUNTER_PROPOSAL.ACTION,
    onSendCounterProposalSuccess
  )

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="selectProviderOnCancelDialog">
      <DialogContent>
        <DialogContentText id="selectProviderOnCancelDialog" className={styles.description}>
          Que tal se você nos deixar procurar um outro prestador que aceite este seu valor
          informado?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" isLoading={isLoading}>
          Não, feche o orçamento em questão
        </Button>
        <Button
          onClick={onSearchForAnotherProvider}
          color="primary"
          variant="contained"
          isLoading={isLoading}
        >
          Sim, pode procurar outro prestador
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SelectProviderDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.string.isRequired,
  onSendCounterProposalSuccess: PropTypes.func.isRequired,
}

export default React.memo(SelectProviderDialog)

/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Typography, Grid, Box, Drawer, IconButton, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Button from '_components/button'
import AttachedPictures from '_components/attached-pictures'
import Textfield from '_components/textfield'
import Svg from '_components/svg'
import useForm from '_hooks/use-form'
import { Budget } from '_models'
import ApproveIcon from '_assets/icons/ic-aprovar-blue.svg'
import CloseIcon from '_assets/icons/ic-close.svg'
import { approveBudget } from '_modules/budget/actions'
import { formatAmount } from '_utils/helpers'

import useStyles from './styles'
import { validate } from './validate'

const formState = {
  name: '',
  email: '',
  cpf: '',
  photos: [],
}

const ApproveBudgetDrawer = ({ ...props }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { onClose, budget, isOpen, serviceOrderId } = props

  const {
    values: { name, email, cpf, photos },
    handleInputChange,
    setFieldValue,
    validateForm,
    errors,
  } = useForm(formState, validate, { keepArrays: true, skipInitialValidation: true })

  const onSendClick = event => {
    event.preventDefault()
    const validationResult = validateForm()
    const error = Object.values(validationResult).length
    if (error || !budget?.id || !serviceOrderId) return
    const payload = {
      person_paying_name: name,
      person_paying_cpf: cpf,
      person_paying_email: email,
    }
    if (photos.length) {
      payload.budget_approval_confirmation_pictures = photos.map(({ file }) => file)
    }
    dispatch(approveBudget(serviceOrderId, budget.id, payload))
  }

  const handleAddPictures = photo => {
    const newPhotos = [...photos, { file: photo, id: photo.lastModified }]
    setFieldValue('photos', newPhotos)
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} {...props}>
      {' '}
      <Grid component="form">
        <Box className={styles.box}>
          <Grid component="header" className={styles.header}>
            <Svg icon={ApproveIcon} className={styles.rejectIcon} />
            <Typography className={`${styles.title} big`} typography="h4" component="h1">
              Aprovar orçamento ID #{budget?.id}
            </Typography>
            <IconButton onClick={onClose} aria-label="Fechar menu">
              <Svg icon={CloseIcon} className={styles.close} />
            </IconButton>
          </Grid>
          <Divider className={styles.divider} />
          <Grid className={styles.content}>
            <Grid container alignItems="center" justify="space-between">
              <Typography variant="h5" component="p" className={styles.label}>
                Valor
              </Typography>
              <Typography variant="h5" component="p" color="primary" className={styles.total}>
                {budget?.totalPrice ? `R$ ${formatAmount(budget.totalPrice)}` : 'Não informado'}
              </Typography>
            </Grid>
            <Grid className={styles.info}>
              <Typography component="p" className={styles.title}>
                Dados do responsável pelo pagamento (Nota fiscal)
              </Typography>
              <Textfield
                name="name"
                label="Nome"
                onChange={handleInputChange}
                value={name}
                className={styles.input}
                error={errors?.name}
                helperText={errors?.name && errors?.name}
              />
              <Textfield
                isCpfCnpj
                name="cpf"
                label="CPF/CNPJ"
                onChange={handleInputChange}
                value={cpf}
                className={styles.input}
                inputProps={{ maxLength: 18 }}
                error={errors?.cpf}
                helperText={errors?.cpf && errors?.cpf}
              />
              <Textfield
                name="email"
                label="Email"
                onChange={handleInputChange}
                value={email}
                className={styles.input}
                error={errors?.email}
                helperText={errors?.email && errors?.email}
              />
            </Grid>
            <Grid>
              <Typography variant="h5" component="p">
                Anexar comprovante de aprovação
              </Typography>
              <AttachedPictures
                isEditing
                isIntegrated
                name="photos"
                setFieldValue={setFieldValue}
                classNameButton={styles.attachFileButton}
                classNameIcon={styles.iconAttachFile}
                pictures={photos}
                handleAddPictures={handleAddPictures}
              />
            </Grid>
            <Grid container className={styles.buttonContainer}>
              <Button
                className={styles.button}
                variant="contained"
                color="primary"
                onClick={onSendClick}
                type="submit"
              >
                Salvar
              </Button>
              <Button
                className={`${styles.button} outlined left`}
                variant="outlined"
                onClick={onClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Drawer>
  )
}

ApproveBudgetDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budget: PropTypes.instanceOf(Budget).isRequired,
}

export default ApproveBudgetDrawer

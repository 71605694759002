/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { cancelServiceOrderRejected, getServiceOrder } from '_modules/service-orders/actions'

// components
import { Alert, useConfirm, Toast, Dialog } from '@refera/ui-web'
import { Danger as DangerIcon } from '@refera/ui-icons'
import CancelRejectedModal from './components/CancelRejectedModal'
import Button from '_components/button'
import TextField from '_components/textfield'
// eslint-disable-next-line no-unused-vars
import ConfirmCancelModal, { SUCCESS_MODAL } from '_components/modal/confirm-cancel-modal'
// eslint-disable-next-line import/named
import RatingModal, { WARNING_MODAL } from '_components/modal/rating-modal'
import CancelConfirmModal from '_components/modal/cancel-confirm-modal'
import CancelApprovedModal from '_components/modal/cancel-approved-modal'
import ResendEmailModal from '_components/modal/resend-email-on-cancel-rejected-modal'
import {
  getApprovedServiceSelector,
  getSendEmailDefinition,
} from '_modules/service-orders/selectors'
import { getHistoryLogsSelector } from '_modules/history-logs/selectors'

import useStyles from './styles'
import { getHistoryLogs } from '_/modules/history-logs/actions'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const defaultToast = { isOpen: false, title: '', severity: '' }

const ServiceApproval = () => {
  const styles = useStyles()
  const { serviceOrderId } = useParams()
  const { checkUserPermission } = useRolePermission()

  const [confirmCancelModal, setConfirmCancelModal] = useState({ isOpen: false, subTitle: '' })
  const [cancelConfirmModal, setCancelConfirmModal] = useState({ isOpen: false, title: '' })
  const [cancelApprovedModal, setCancelApprovedModal] = useState({ isOpen: false, subTitle: '' })
  const [resendEmailModal, setResendEmailModal] = useState({ isOpen: false, subTitle: '' })
  const [openDialog, setOpenDialog] = useState({ open: false, message: '' })

  const approvedService = useSelector(state => getApprovedServiceSelector(state, serviceOrderId))
  const sendEmailDefinition = useSelector(state => getSendEmailDefinition(state))
  const [objRespo, setObjRespo] = useState()
  const [optionReprove, setOptionReprove] = useState([])
  const [ratingModal, setRatingModal] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })
  const dispatch = useDispatch()
  const [toastInfo, setToastInfo] = useState(defaultToast)

  useEffect(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [])

  const historyLogs = useSelector(getHistoryLogsSelector)
  const user = useMemo(
    () => historyLogs?.find(log => log.logType === 'end_of_service_approved')?.userName,
    [historyLogs]
  )
  const respoJson = JSON.stringify(approvedService?.responsible)

  useEffect(() => {
    if (respoJson) {
      setObjRespo(JSON.parse(respoJson))
    }
  }, [approvedService, respoJson])

  useEffect(() => {
    if (approvedService) {
      const arrayOfOptionsSelectedReprovedService =
        approvedService?.optionsSelectedReprovedService?._tail?.array
      setOptionReprove(arrayOfOptionsSelectedReprovedService)
    }
  }, [approvedService])
  const onConfirm = useCallback(() => {
    if (
      approvedService?.serviceorderRatingRequester.toJS().length !== 0 &&
      !approvedService?.serviceorderRatingRequester?.toJS().pop().solutionApproved
    ) {
      setRatingModal({
        isOpen: true,
        title: 'Atenção!',
        subTitle: 'O Solicitante não aprovou a finalização em seu feedback sobre o serviço.',
        type: WARNING_MODAL,
      })
    }
    setConfirmCancelModal({
      isOpen: true,
      subTitle: 'Você tem certeza que deseja aprovar a finalização dos serviços deste chamado?',
      type: SUCCESS_MODAL,
    })
  }, [setConfirmCancelModal, setRatingModal, serviceOrderId])

  const onCancel = useCallback(() => {
    setCancelConfirmModal({
      isOpen: true,
      title: 'Reprovar serviço finalizado',
    })
  }, [setCancelConfirmModal, serviceOrderId])

  const handleServiceReproveSuccess = useCallback(
    success => {
      if (success) {
        setToastInfo({ isOpen: true, title: 'Ação executada com sucesso.', severity: 'success' })
        dispatch(getHistoryLogs(serviceOrderId))
        setCancelConfirmModal({ ...cancelConfirmModal, isOpen: false })
        return
      }
      setToastInfo({
        isOpen: true,
        title: 'Ocorreu um erro ao realizar a operação.',
        severity: 'error',
      })
    },
    [dispatch]
  )

  const onCancelApproved = useCallback(() => {
    setCancelApprovedModal({
      isOpen: true,
      subTitle:
        'Você tem certeza que deseja cancelar a aprovação de finalização dos serviços deste chamado?',
    })
  }, [setCancelApprovedModal, serviceOrderId])

  const { isConfirmed } = useConfirm()
  const onCancelRejected = useCallback(async () => {
    const confirmed = await isConfirmed()

    if (confirmed) {
      dispatch(cancelServiceOrderRejected(serviceOrderId)).catch(e =>
        setOpenDialog({ open: true, message: e.error_code })
      )
    }
  }, [isConfirmed])

  const onCloseDialog = useCallback(() => {
    setOpenDialog({ open: false, message: '' })
  }, [openDialog, onCancelApproved])

  useEffect(() => {
    setResendEmailModal({
      isOpen: sendEmailDefinition,
      subTitle:
        'Já foi enviado um email ao prestador informando sobre tal reprovação. Você deseja enviar outro email pedindo para desconsiderar este primeiro email?',
    })
  }, [sendEmailDefinition])

  return (
    <>
      {approvedService?.stepStatus === 'waiting_finalization_approval' ? (
        <Grid className={styles.container}>
          <Typography className={styles.containerTitle}>Chamado Finalizado</Typography>
          <Typography className={styles.label}>
            O prestador finalizou o chamado. Deseja aprovar a finalização de chamado?
          </Typography>
          <Grid className={styles.buttons}>
            <Button
              color="primary"
              variant="contained"
              className={styles.confirmButton}
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
                  PERMISSIONS_ACTIONS.ADD,
                  onConfirm
                )
              }
            >
              Aprovar Chamado
            </Button>
            <Button
              color="red"
              variant="outlined"
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
                  PERMISSIONS_ACTIONS.ADD,
                  onCancel
                )
              }
            >
              Reprovar Chamado
            </Button>
          </Grid>
          <ConfirmCancelModal
            confirmCancelModal={confirmCancelModal}
            setConfirmCancelModal={setConfirmCancelModal}
          />
          <CancelConfirmModal
            cancelConfirmModal={cancelConfirmModal}
            setCancelConfirmModal={setCancelConfirmModal}
            onCancelSuccess={handleServiceReproveSuccess}
          />
          <RatingModal ratingModal={ratingModal} setRatingModal={setRatingModal} />
        </Grid>
      ) : null}
      {approvedService?.stepStatus === 'service_order_finished' && (
        <Grid className={styles.container}>
          <Typography className={styles.containerTitle}>Serviço Finalizado</Typography>
          {objRespo && (
            <Typography className={styles.label}>O serviço foi aprovado por {user}.</Typography>
          )}
          <Grid className={styles.buttons}>
            <Button
              color="red"
              variant="outlined"
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
                  PERMISSIONS_ACTIONS.ADD,
                  onCancelApproved
                )
              }
            >
              Cancelar Aprovação
            </Button>
          </Grid>
          <CancelApprovedModal
            cancelApprovedModal={cancelApprovedModal}
            setCancelApprovedModal={setCancelApprovedModal}
          />
        </Grid>
      )}
      {approvedService?.status === 'service_finished_repproved' && (
        <Grid className={styles.container}>
          <Typography className={styles.containerTitle}>Serviço Reprovado</Typography>
          <Typography className={styles.labelText}>Relacionado a</Typography>
          <Typography className={styles.text}>
            {optionReprove
              .map(option => {
                if (option === 'servicos_executados') {
                  return 'Serviços executados'
                }
                if (option === 'nota_fiscal_emitida') {
                  return 'Nota fiscal emitida'
                }
                if (option === 'fotos_anexadas') {
                  return 'Fotos anexadas'
                }
                return 'Outros motivos'
              })
              .join(', ')}
            .
          </Typography>
          <Typography className={styles.labelText}>Motivos</Typography>
          <TextField
            className={styles.input}
            value={approvedService?.reasonsReproveService}
            variant="outlined"
            multiline
            disabled
          />
          <Grid className={styles.buttons}>
            <Button
              color="red"
              variant="outlined"
              onClick={() =>
                checkUserPermission(
                  PERMISSIONS.APPROVE_REJECT_BUDGET_CANCEL_ACTION,
                  PERMISSIONS_ACTIONS.ADD,
                  onCancelRejected
                )
              }
            >
              Cancelar Reprovação
            </Button>
          </Grid>
          <CancelRejectedModal />
        </Grid>
      )}

      <ResendEmailModal
        resendEmailModal={resendEmailModal}
        setResendEmailModal={setResendEmailModal}
      />
      <Toast
        draggable
        open={toastInfo.isOpen}
        autoHideDuration={6000}
        onClose={() => setToastInfo(defaultToast)}
      >
        <Alert
          severity={toastInfo.severity}
          title={toastInfo.title}
          onClose={() => setToastInfo(defaultToast)}
        />
      </Toast>
      <Dialog
        open={openDialog?.open}
        icon={DangerIcon}
        type="error"
        description={openDialog?.message}
        onApprove={onCloseDialog}
      />
    </>
  )
}

export default React.memo(ServiceApproval)

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'

import Button from '_components/button'
import { unrefuseBudget, UNREFUSE_BUDGET } from '_modules/budget/actions'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import useIsCurrentStepStatusCanceled from '_hooks/use-is-current-step-status-canceled'

import useStyles from './styles'
import { getHistoryLogs } from '_/modules/history-logs/actions'

const DIALOG_STATES = {
  DEFAULT: 'default',
  CONFIRM: 'confirm',
  HOLD: 'hold',
}

const UnrefuseBudgetDialog = ({
  isOpen,
  onClose,
  serviceOrderId,
  budgetId,
  hasAnotherAvailableBudget,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const serviceOrder = useSelector(currentServiceOrderSelector)

  const isActive = useMemo(() => Boolean(serviceOrder?.isActive), [serviceOrder?.isActive])
  const isCanceled = useIsCurrentStepStatusCanceled()

  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })

  const [dialogState, setState] = useState(
    isActive && !isCanceled ? DIALOG_STATES.CONFIRM : DIALOG_STATES.DEFAULT
  )

  const [isLoading] = useFetchCall(UNREFUSE_BUDGET.ACTION, onClose)
  const onConfirmClick = useCallback(() => {
    if (hasAnotherAvailableBudget) {
      setState(DIALOG_STATES.HOLD)
      return
    }

    setState(DIALOG_STATES.CONFIRM)
  }, [hasAnotherAvailableBudget])

  const onMarkBudgetAsRevised = useCallback(() => {
    dispatch(unrefuseBudget({ serviceOrderId, budgetId }))
  }, [budgetId, dispatch, serviceOrderId])

  useFetchCall(
    UNREFUSE_BUDGET.ACTION,
    () => {
      dispatch(getHistoryLogs(serviceOrderId))
    },
    errorMessage => {
      errorMessage.map(erro =>
        setModalDialog({
          isOpen: true,
          subTitle: erro,
          type: WARNING_MODAL,
        })
      )
    }
  )

  const renderDialogContent = useMemo(() => {
    switch (dialogState) {
      case DIALOG_STATES.CONFIRM:
        return (
          <>
            <DialogContent>
              <DialogContentText id="unrefuseBudgetDialog" className={styles.description}>
                Você tem certeza que deseja cancelar a recusa deste orçamento?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined">
                Não
              </Button>
              <Button
                onClick={onMarkBudgetAsRevised}
                isLoading={isLoading}
                color="primary"
                variant="contained"
              >
                Sim
              </Button>
            </DialogActions>
          </>
        )
      case DIALOG_STATES.HOLD:
        return (
          <DialogContent>
            <DialogContentText id="unrefuseBudgetDialog" className={styles.description}>
              Este chamado possui outro orçamento aguardando aprovação ou reprovação. Reprove os
              outros orçamento ainda ativos para só depois cancelar a recusa deste orçamento.
            </DialogContentText>
          </DialogContent>
        )

      default:
        return (
          <>
            <DialogContent>
              <DialogContentText id="unrefuseBudgetDialog" className={styles.description}>
                Este chamado encontra-se cancelado. Para realizar ações em orçamentos, reative o
                chamado.
              </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.button}>
              <Button onClick={onClose} variant="contained" color="primary">
                Ok
              </Button>
            </DialogActions>
          </>
        )
    }
  }, [
    dialogState,
    isLoading,
    onClose,
    onConfirmClick,
    onMarkBudgetAsRevised,
    styles.description,
    styles.button,
  ])

  return (
    <Dialog open={isOpen} onClose={onClose} aria-describedby="unrefuseBudgetDialog">
      {renderDialogContent}
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
    </Dialog>
  )
}

UnrefuseBudgetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serviceOrderId: PropTypes.string.isRequired,
  budgetId: PropTypes.number.isRequired,
  hasAnotherAvailableBudget: PropTypes.bool.isRequired,
}

export default React.memo(UnrefuseBudgetDialog)

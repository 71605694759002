/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from '@reach/router'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmCancelJustified, { SUCCESS_MODAL } from '_components/modal/confirm-cancel-justified'
import {
  getMessageSubjectOptions,
  createHelpRequest,
  updateMessage,
  deleteMessageAttachment,
} from '_/modules/messages/actions'
import Button from '_components/button'
import { getUser } from '_/modules/authentication/actions'
import { getTradesmanById } from '_/modules/service-orders/actions'
import { getOptionsTypesDelay } from '_modules/options-type/actions'
import { userSelector } from '_/modules/authentication/selectors'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import { getMessageSubjectSelectorList } from '_modules/messages/selector'

import { Preview, useConfirm, Dialog as ReferaDialog } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'
import { CloseCircle as CloseCircleIcon } from '@refera/ui-icons'
import useToggle from '_hooks/use-toggle'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import { FormControl } from '@mui/material'
import { useToast } from '_/hooks/use-toast'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const JustifiedDelayForm = ({ closeModal, message, setMessage, tradesmanInfo }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(message?.expectedDeadline || new Date())
  const [sender, setSender] = useState()
  const [optionsTypeDelayList, setOptionsTypeDelayList] = useState([])
  const [messageText, setMessageText] = useState('')
  const [confirmCancelJustified, setConfirmCancelJustified] = useState({
    isOpen: false,
    subTitle: '',
    messageId: '',
  })

  const [editAttachments] = useToggle()
  const [picturesLoading, setPicturesLoading] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const { isConfirmed } = useConfirm()
  const [toDelete, setToDelete] = useState([])
  const filteredSubjectOptionList = useSelector(getMessageSubjectSelectorList)
  const { showToast } = useToast()

  function getDefaultValueFromMessage() {
    if (message?.justifieType) {
      return message?.justifieType
    }

    if (message?.type) {
      if (message?.type === 'budget_justified') {
        return 'Justificativa para orçamento'
      }
      return 'Justificativa para agendamento de execução'
    }
    return ''
  }

  const messageTypeInputDefaultValue = getDefaultValueFromMessage()

  const subjectOptionsList = useMemo(() => {
    if (filteredSubjectOptionList?.length)
      return filteredSubjectOptionList.map(option => ({
        label: option.option,
        value: option.status,
        typeDelayName: option.typeDelayName,
        attachmentRequired: option.attachmentRequired,
      }))
    return []
  }, [filteredSubjectOptionList])

  const [justifiedDelay, setJustifiedDelay] = useState(() =>
    message?.type === 'budget_justified'
      ? 'Justificativa para orçamento'
      : 'Justificativa para agendamento de execução'
  )
  const [reasonDelay, setReasonDelay] = useState(message?.subject)

  const [messageAttachments, setMessageAttachments] = useState(() =>
    message?.pendingAttachment
      ? message?.pendingAttachment?.map(file => ({ file: file?.file, id: file?.id }))
      : []
  )

  const attachmentsImages = useMemo(
    () =>
      messageAttachments.filter(file =>
        file instanceof File ? file.type !== 'application/pdf' : file.file.indexOf('.pdf') === -1
      ),
    [messageAttachments]
  )

  const PreviewPictureComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          attachmentsImages &&
          attachmentsImages.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.file,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, messageAttachments]
  )

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const { serviceOrderId } = useParams()
  const user = useSelector(userSelector)

  useEffect(() => {
    if (!user) {
      dispatch(getUser())
    }
    setSender(tradesmanInfo[0]?.id)
  }, [dispatch, user])

  const handleGetOptionsType = async () => {
    await Promise.resolve(dispatch(getOptionsTypesDelay())).then(res =>
      setOptionsTypeDelayList(res)
    )
  }

  const handleJustifiedDelay = e => {
    setJustifiedDelay(e.target.value)
  }

  const handleReasonDelay = (event, newValue) => {
    setReasonDelay(newValue?.value)
  }

  useEffect(() => {
    handleGetOptionsType()
  }, [])

  const handleGetOptionsReason = async () => {
    const params = {
      typeSubject: 'justified_delayed',
    }
    await Promise.resolve(dispatch(getMessageSubjectOptions(params)))
  }

  useEffect(() => {
    handleGetOptionsReason()
  }, [])

  const handleMessageText = e => {
    setMessageText(e.target.value)
  }

  const { handleSubmit, register } = useForm({ mode: 'all' })
  const handleCreateMessage = async data => {
    const request = data
    let type = ''
    setPicturesLoading(prevState => !prevState)

    const newAttachments = messageAttachments.filter(item => item?.id === undefined)

    if (justifiedDelay === 'Justificativa para orçamento') {
      type = 'budget_justified'
    } else if (justifiedDelay === 'Justificativa para agendamento de execução') {
      type = 'execution_justified'
    }

    const attachmentRequiredFlag =
      reasonDelay && options.find(option => option?.value === reasonDelay)?.attachmentRequired

    if (attachmentRequiredFlag && !newAttachments?.length) {
      setShowConfirmation(attachmentRequiredFlag)
      return
    }

    if (message?.subject) {
      request.id = message.id
      request.type = type
      request.subject = reasonDelay
      request.expectedDeadline = format(new Date(selectedDate), 'yyyy-MM-dd', {
        locale: ptBR,
      })
      if (newAttachments) {
        request.pendingAttachment = newAttachments
      }
      if (messageText) {
        request.message = messageText
      }
      if (toDelete.length > 0) {
        toDelete.map(fileID => dispatch(deleteMessageAttachment(message.id, fileID)))
        setToDelete([]) // RESETS ARRAY TO AVOID UNECESSARY DELETE REQUESTS ON FUTURE SAVE ATTEMPTS
      }
      dispatch(updateMessage(request.id, request)).then(newValue => {
        setMessage(newValue)
      })
    } else {
      request.origin = 'justified_delayed'
      request.type = type
      request.subject = reasonDelay
      request.serviceOrder = Number(serviceOrderId)
      request.isImportant = 'true'
      request.pendingAttachment = newAttachments

      if (sender > 0) {
        await Promise.resolve(dispatch(getTradesmanById(sender))).then(res => {
          request.sender = res?.user?.id
          request.sender_name = res?.user?.name
        })
      }

      request.expectedDeadline = format(new Date(selectedDate), 'yyyy-MM-dd', {
        locale: ptBR,
      })
      request.resolved = 'true'
      request.justifiedDelayApproved = 'true'
      request.fromTo = 'tradesman_to_refera'
      dispatch(createHelpRequest(request))
        .then(newValue => {
          setMessage(newValue)
        })
        .catch(error => {
          showToast({ type: 'error', message: error?.error_message })
        })
    }
    closeModal()
  }

  const onConfirm = useCallback(
    messageId => {
      setConfirmCancelJustified({
        isOpen: true,
        subTitle: 'Você tem certeza que deseja excluir este registro?', // Mensagem de confirmação
        messageId,
        type: SUCCESS_MODAL,
      })
    },
    [setConfirmCancelJustified]
  )

  const handleDate = e => {
    setSelectedDate(e)
  }

  const handleRemoveAttachments = useCallback(
    async index => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = attachmentsImages[index]
        if (currentFile?.id) {
          setToDelete([...toDelete, currentFile.id])
          setMessageAttachments(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setMessageAttachments(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [messageAttachments, setDialogMessage]
  )

  const options = useMemo(() => {
    return subjectOptionsList.reduce((accumulator, option) => {
      if (option?.typeDelayName === justifiedDelay) {
        accumulator.push({
          value: option?.value,
          label: option?.label,
          attachmentRequired: option?.attachmentRequired,
        })
      }
      return accumulator
    }, [])
  }, [subjectOptionsList, justifiedDelay])

  return (
    <Dialog
      open
      maxWidth="xl"
      disableEscapeKeyDown
      onClose={closeModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      className={styles.dialog}
    >
      <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
        {!message?.subject ? 'Novo - Atraso justificado' : 'Atraso justificado aprovado'}
      </DialogTitle>
      <DialogContent className={styles.container}>
        <form
          id="justifiedForm"
          onSubmit={handleSubmit(handleCreateMessage)}
          className={styles.subcontainer}
        >
          <>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Solicitante</Typography>
                </Grid>
                <Typography className={styles.label}>{tradesmanInfo[0]?.name}</Typography>
              </Grid>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Tipo de justificativa</Typography>
                  <Typography className={styles.required}>*</Typography>
                </Grid>
                <Select
                  name="type"
                  className={styles.selectPlaceholder2}
                  MenuProps={menuProps}
                  onChange={handleJustifiedDelay}
                  defaultValue={messageTypeInputDefaultValue}
                  displayEmpty
                  // renderValue={message?.subject ? newType : ''}
                  required
                >
                  <MenuItem disabled value="">
                    Selecione a justificativa
                  </MenuItem>
                  {optionsTypeDelayList?.results?.map(option => {
                    return (
                      <MenuItem key={option} value={option?.name}>
                        {option?.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Motivo</Typography>
                  <Typography className={styles.required}>*</Typography>
                </Grid>
                <FormControl>
                  <Autocomplete
                    id="subject"
                    options={options}
                    getOptionLabel={option => option?.option ?? option?.label}
                    multiple={false}
                    value={subjectOptionsList.find(option => option.value === reasonDelay) ?? ''}
                    required
                    noOptionsText="Nenhum assunto encontrado com esse nome"
                    onChange={handleReasonDelay}
                    renderInput={params => (
                      <TextField {...params} required placeholder="Selecione um motivo" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Resolverei até:</Typography>
                  <Typography className={styles.required}>*</Typography>
                </Grid>
                <KeyboardDatePicker
                  variant="inline"
                  name="expected_date"
                  ampm={false}
                  value={selectedDate}
                  onChange={handleDate}
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/AAAA"
                  emptyLabel="DD/MM/AAAA"
                  className={styles.selectPlaceholder2}
                  // minDate={new Date()}
                />
              </Grid>
            </Grid>
            <Grid className={styles.subjectWrapper}>
              <Grid className={styles.inlineLabel}>
                <Typography className={styles.labels}>Mensagem</Typography>
                <Typography className={styles.required}>*</Typography>
              </Grid>

              <TextField
                multiline
                fullWidth
                required={!message?.subject || message?.senderId !== user?.id}
                rows={5}
                name="message"
                defaultValue={message?.subject ? message?.message : messageText}
                onChange={handleMessageText}
                placeholder="Escreva aqui a mensagem do atraso justificado."
                inputProps={{
                  maxLength: 255,
                }}
                inputRef={register({ maxLength: 255 })}
                variant="outlined"
                InputProps={{ style: { fontSize: 14 } }}
              />
              <ConfidentialDataWarning style={{ marginTop: 10 }} />
            </Grid>
            <Grid>
              <Grid container>
                <Typography variant="h5" component="p" className={styles.label}>
                  Anexos
                </Typography>
              </Grid>
              {picturesLoading ? (
                <Grid container justify="center" alignItems="center">
                  <CircularProgress size={40} />
                </Grid>
              ) : (
                <>
                  <Attachments
                    label="Imagens e vídeos"
                    readOnly={editAttachments}
                    files={attachmentsImages || []}
                    accept={{
                      'image/jpeg': [
                        '.jpeg',
                        '.png',
                        '.jpg',
                        '.bmp',
                        '.flv',
                        '.mkv',
                        '.mp4',
                        '.mov',
                      ],
                    }}
                    onDrop={files => setMessageAttachments(old => [...old, ...files])}
                    onRemoveItem={index => handleRemoveAttachments(index, false)}
                    onItemClick={index => {
                      window.open(attachmentsImages[index]?.file)
                    }}
                  />
                </>
              )}
            </Grid>
            {PreviewPictureComponent}
            <ConfirmRemoveDialog message={dialogMessage} />
            <ConfirmCancelJustified
              confirmCancelJustified={confirmCancelJustified}
              setConfirmCancelJustified={setConfirmCancelJustified}
            />
          </>
        </form>
      </DialogContent>
      {message?.subject && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Button
          className={styles.linkButton}
          onClick={() => onConfirm(message?.id)}
          color="#1671ED"
        >
          Remover atraso justificado
        </Button>
      )}
      <DialogActions className={message?.subject ? styles.dialogAction2 : styles.dialogAction}>
        <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
          Cancelar
        </Button>
        {!showConfirmation ? (
          <Button
            className={styles.button}
            form="justifiedForm"
            type="submit"
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        ) : (
          <ReferaDialog
            open={showConfirmation}
            icon={CloseCircleIcon}
            type="info"
            subject="Atenção!"
            description={
              <span>
                Para cadastrar um atraso justificado com este Motivo, você deve incluir um anexo de
                comprovação. Dúvidas,{' '}
                <a
                  href="https://refera.movidesk.com/kb/article/436307/obrigatoriedade-de-anexos-em-atrasos-justificados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  clique aqui
                </a>
                .
              </span>
            }
            onApprove={() => {
              setShowConfirmation(false)
              closeModal()
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

JustifiedDelayForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default JustifiedDelayForm

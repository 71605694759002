import React, { useCallback, useState } from 'react'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import DialogModal from '_components/modal/modal-dialog'
import Button from '_components/button'

import { getPaymentAnticipationSelector } from '_modules/service-orders/selectors'
import useFetchCall from '_hooks/use-fetch-call'

import useStyles from './styles'
import {
  CREATE_ANTICIPATIONS_TRADESMAN,
  createAnticipationsTradesman,
} from '_/modules/finance/actions'
import { formatCurrency } from '_/utils/helpers'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const PaymentAnticipationForm = ({ closeModal }) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const [modalDialog, setModalDialog] = useState()
  const paymentAnticipationData = useSelector(getPaymentAnticipationSelector)

  const handleSubmit = useCallback(() => {
    const payload = {
      budgetId: paymentAnticipationData?.budget,
    }

    dispatch(createAnticipationsTradesman(payload))
  }, [paymentAnticipationData])

  const [isLoading] = useFetchCall(CREATE_ANTICIPATIONS_TRADESMAN.ACTION, closeModal)

  return (
    <>
      {modalDialog ? (
        <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />
      ) : (
        <Dialog
          open
          maxWidth="xl"
          disableEscapeKeyDown
          onClose={closeModal}
          PaperComponent={PaperComponent}
          TransitionComponent={Transition}
          className={styles.dialog}
        >
          <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
            Novo - Antecipação de pagamento
          </DialogTitle>
          <DialogContent className={styles.container}>
            <Typography className={styles.textlabel}>
              Confirme as informações e clique em “Salvar” para criar o pedido de antecipação em
              nome do prestador.
            </Typography>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Solicitante</Typography>
                </Grid>
                <Typography className={styles.label}>{paymentAnticipationData?.name}</Typography>
              </Grid>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>E-mail da empresa</Typography>
                </Grid>
                <Typography className={styles.label}>{paymentAnticipationData?.email}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={styles.wrapper}>
              <Grid item xs={6} className={styles.subjectWrapper}>
                <Grid className={styles.inlineLabel}>
                  <Typography className={styles.labels}>Valor do prestador no orçamento</Typography>
                </Grid>
                <Typography className={styles.label}>
                  {formatCurrency(paymentAnticipationData?.price)}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={styles.dialogAction}>
            <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
              Cancelar
            </Button>
            <Button
              className={styles.button}
              form="justifiedForm"
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

PaymentAnticipationForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default PaymentAnticipationForm

import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { returnBudgetProvider, RETURN_BUDGET_PROVIDER } from '_modules/budget/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import Button from '_components/button'
import TextField from '_components/textfield'
import useFetchCall from '_hooks/use-fetch-call'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const ReturnToProvider = ({ isOpen, closeModal, serviceOrderId, budgetId }) => {
  const styles = useStyles()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [returnToProvider, setReturnToProvider] = useState('')
  const [sendWhatsapp, setSendWhatsapp] = useState()
  const handleProvider = e => {
    setReturnToProvider(e.target.value)
  }
  const handleWhatsapp = e => {
    setSendWhatsapp(e.target.checked)
  }
  const handleReturnToProvider = async () => {
    await Promise.resolve(
      dispatch(
        returnBudgetProvider(serviceOrderId, budgetId, {
          reason_devolution: returnToProvider,
          send_whatsapp: sendWhatsapp,
        })
      ).then(() => {
        return navigate(`/chamado/${serviceOrderId}`)
      })
    )
  }

  useFetchCall(RETURN_BUDGET_PROVIDER.ACTION, () => {
    dispatch(getHistoryLogs(serviceOrderId))
  })

  return (
    <Dialog
      open={isOpen}
      maxWidth="xl"
      disableEscapeKeyDown
      onClose={closeModal}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      className={styles.dialog}
    >
      <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
        Orçamento Devolvido ao Prestador
      </DialogTitle>
      <DialogContent className={styles.container}>
        <Grid className={styles.empty}>
          <Typography className={styles.textHeader}>Motivo</Typography>
        </Grid>
        <TextField
          className={classes.inputComment}
          variant="outlined"
          multiline
          inputProps={{
            maxLength: 1000,
          }}
          rows={4}
          name="reason_devolution"
          value={returnToProvider}
          onChange={handleProvider}
        />
        <ConfidentialDataWarning style={{ marginTop: 8 }} />
        <FormControlLabel
          control={<Checkbox name="send_whatsapp" value={sendWhatsapp} onChange={handleWhatsapp} />}
          label={<Typography color="#434343">Comunicar por WhatsApp</Typography>}
        />
      </DialogContent>

      <DialogActions className={styles.dialogAction}>
        <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
          Cancelar
        </Button>
        <Button
          className={styles.button}
          onClick={handleReturnToProvider}
          color="primary"
          variant="contained"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReturnToProvider.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(ReturnToProvider)

import React, { useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { useForm } from 'react-hook-form'

import * as Modal from '_components/modal/generic-modal'
import { TextField, Typography, FormHelperText } from '@material-ui/core'

import useFetchCall from '_hooks/use-fetch-call'
import { getHistoryLogs } from '_/modules/history-logs/actions'
import { updateBudget, UPDATE_BUDGET } from '_modules/budget/actions'

import useStyles from './styles'

const IntermediaryRefuseModal = ({
  open,
  budgetId,
  serviceOrderId,
  handleModal = () => {},
  handleRevised = () => {},
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const formRef = useRef()
  const { showToast } = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  const onSubmit = useCallback(
    payload => {
      dispatch(
        updateBudget(serviceOrderId, budgetId, {
          refusedByIntermediary: true,
          ...payload,
        })
      )
    },
    [budgetId, dispatch, serviceOrderId]
  )

  const onSuccess = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))

    if (open) {
      handleRevised()
      handleModal()
    }
  }, [handleModal, open])

  const onFailed = useCallback(() => {
    if (open) {
      showToast({
        type: 'error',
        message: 'Ocorreu um erro na ação.',
      })
    }
  }, [open])

  const [isLoading] = useFetchCall(UPDATE_BUDGET.ACTION, onSuccess, onFailed)

  return (
    <Modal.Root open={open} maxWidth="sm" keepMounted>
      <Modal.TitleModal title="Recusa de orçamento" />

      <Modal.Content className={styles.modalContent}>
        <form className={{ width: '600px' }} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Typography>Digite abaixo o motivo de recusa.</Typography>
          <TextField
            multiline
            maxRows={5}
            minRows={5}
            id="reason"
            name="reason"
            inputRef={register({
              required: 'Campo requerido.',
              maxLength: { value: 500, message: 'Máximo de 500 caractéres.' },
            })}
            fullWidth
            className={styles.input}
          />
          {errors?.reason && (
            <FormHelperText style={{ fontSize: '12px', marginTop: '10px', color: 'red' }}>
              {errors?.reason?.message}
            </FormHelperText>
          )}
        </form>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonRed onClick={() => handleModal()}>Cancelar</Modal.ButtonRed>
        <Modal.ButtonFullBlue
          disabled={isLoading}
          onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))}
        >
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}

export default IntermediaryRefuseModal

import React, { useMemo, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { displayCurrency, formatDate } from '_utils/helpers'
import AttachedPictures from '_components/attached-pictures'

import useStyles from './styles'
import { activeBudgetSelector } from '_/modules/service-orders/selectors'

const ApprovedBudgetInfo = ({ serviceOrder }) => {
  const styles = useStyles()
  const approvedBudget = useSelector(activeBudgetSelector)
  const updatedDate = serviceOrder?.datetimeExecutionBudgetApproved
  const formattedDate = updatedDate ? formatDate(updatedDate) : ''
  const payerData = approvedBudget?.budgetPayer?.[0]

  const [attachments, setAttachments] = useState(
    approvedBudget?.budgetApprovalConfirmationPictures?.map(file => ({
      file: file?.picture,
      id: file?.id,
    }))
  )

  const formatTotalValue = useMemo(() => {
    if (!approvedBudget) return '0,00'

    const { priceAgency = 0, priceRefera = 0, priceServiceProvider = 0 } = approvedBudget
    const totalPrice = priceAgency + priceRefera + priceServiceProvider
    return displayCurrency(totalPrice)
  }, [approvedBudget])

  const PAYMENT_RESPONSIBLE_OPTIONS = {
    requester: 'Solicitante',
    owner: 'Proprietário',
    intermediary: 'Intermediário',
    other: 'Outro',
  }

  return (
    <>
      <Grid className={styles.container}>
        <Typography className={styles.containerTitle}>Orçamento Aprovado</Typography>
        <Grid className={styles.payerSection}>
          <Grid>
            <Typography className={styles.label}>Nome do pagador</Typography>
            <Typography className={styles.value}>{payerData?.responsibleName || '-'}</Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>CPF/CNPJ do pagador</Typography>
            <Typography className={styles.value}>
              {payerData?.recipientInvoice === 'other'
                ? payerData?.invoiceDocNumber || '-'
                : payerData?.responsibleDocNumber || '-'}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>E-mail do pagador</Typography>
            <Typography className={styles.value}>
              {payerData?.recipientInvoice === 'other'
                ? payerData?.emailInvoice || '-'
                : payerData?.personPayingEmail || '-'}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>Quem é o reponsável pelo pagamento</Typography>
            <Typography className={styles.value}>
              {PAYMENT_RESPONSIBLE_OPTIONS[payerData?.paymentResponsible] || '-'}
            </Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>Data de aprovação do orçamento</Typography>
            <Typography className={styles.value}>{formattedDate || '-'}</Typography>
          </Grid>
          <Grid>
            <Typography className={styles.label}>Valor do orçamento aprovado</Typography>
            <Typography className={styles.value}>
              {formatTotalValue} (em {payerData?.installmentQuantity}
              x)
            </Typography>
          </Grid>
        </Grid>
        <Grid className={styles.picturesSection}>
          <Typography className={styles.label}>Comprovante de aprovação</Typography>
          <AttachedPictures pictures={attachments} setFieldValue={setAttachments} />
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(ApprovedBudgetInfo)

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useEffect, useCallback, useReducer, useState } from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { Alert as ReferaAlert, Toast, Dialog, Loader } from '@refera/ui-web'
import { Danger as DangerIcon } from '@refera/ui-icons'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, navigate } from '@reach/router'
import { AlertTitle, Alert } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'

import Loading from '_components/loading'
import Button from '_components/button'
import Snackbar from '_components/snackbar'
import { ActionDeadline } from '_components/service-order'
import PaperInformation from '_views/service-order/paper-information'
import Actions from '_views/service-order/actions'
import Details from '_views/service-order/details'
import { getCategoriesLoadingSelector } from '_modules/categories/selectors'
import ShowMoreDetailRefuseModal from '_components/modal/show-more-refuse-detail'
import {
  getBudget,
  updateBudget,
  addBudgetPicture,
  GET_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM,
  ADD_BUDGET_PICTURE,
  DELETE_BUDGET_PICTURE,
  REFUSE_BUDGET,
  UNREFUSE_BUDGET,
  SENT_BUDGET_COUNTER_PROPOSAL,
  APPROVE_BUDGET,
  CANCEL_APPROVE_BUDGET,
  deleteBudgetPictures,
  RETURN_BUDGET_PROVIDER,
  verifyPaidInstallment,
} from '_modules/budget/actions'
import {
  getBudgetByIdSelector,
  getBudgetLoadingSelector,
  updateBudgetErrorSelector,
  deleteBudgetItemErrorSelector,
  updateBudgetItemErrorSelector,
  deleteBudgetPictureErrorSelector,
  addBudgetPictureErrorSelector,
} from '_modules/budget/selectors'
import { TRIGGER_SNACKBAR } from '_modules/utils/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { getServiceOrder } from '_modules/service-orders/actions'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { usePrevious } from '_hooks/use-previous'
import { phoneNumberMask, formatDate } from '_utils/helpers'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import {
  TAX_REGIMES,
  SERVICE_ORDER_STATUS,
  FINANCE_PAYLOAD_KEYS_TO_UPDATE_BUDGET,
} from '_utils/constants'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import SwitchComponent from '_/components/switch'

import {
  INITIAL_STATE,
  UPDATE_STATE,
  UPDATE_BUDGET_ITEMS,
  ADD_BUDGET_ITEM,
  REMOVE_BUDGET_ITEM,
  REMOVE_BUDGET_PICTURES,
  ADD_BUDGET_PICTURES,
  reducer,
} from './reducer'
import useStyles from './styles'
import ApproveBudgetDrawer from './approve-budget-drawer'
import UnavailableActionBudgetModal from './unavailable-budget-dialog'
import CancelEditBudgetDialog from './cancel-edit-dialog'
import BudgetButtons from './buttons'
import Textfield from '_/components/textfield'
import { useToast } from '_/hooks/use-toast'

const notAllowedToEditBudgetText =
  'Como este orçamento foi aprovado, apenas os dados do responsável pelo pagamento poderão ser editados.'

const notAllowedToEditFileBudget =
  'Não foi possível realizar o upload dos arquivos, pois a extensão de pelo menos um deles não é aceita pelo campo de anexo em questão.'

const unavailableBudgetText = 'Este não é o Orçamento vigente do <br /> chamado em questão.'
const NOT_ALLOW_EDIT_BUDGET_REFUSED =
  'Como este orçamento foi recusado, nenhum dado pode ser editado. Favor clicar em Cancelar recusa se deseja editar este orçamento.'

const DATE_FORMAT = 'DD/MM/YYYY [às] HH:mm[h]'

const formatNumber = value => (value ? Number(value) : 0)

// FIXME: Fix redux actions removing Promises in order to use the store properly
// FIXME: Fix prop drilling and reduce the number of props passed to children components unnecessarily
const EstimationStep = ({ handleShowHelpRequest }) => {
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const styles = useStyles({ step: serviceOrder?.orderStep })
  const dispatch = useDispatch()
  const { budgetId, serviceOrderId } = useParams()
  const { isAdmin, isManager, isApprover } = useRolePermission()

  const isServicesLoading = useSelector(getCategoriesLoadingSelector)
  const isBudgetLoading = useSelector(getBudgetLoadingSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))?.toJS()
  const updateBudgetError = useSelector(updateBudgetErrorSelector)
  const deleteBudgetItemError = useSelector(deleteBudgetItemErrorSelector)
  const updateBudgetItemError = useSelector(updateBudgetItemErrorSelector)
  const deleteBudgetPictureError = useSelector(deleteBudgetPictureErrorSelector)
  const addBudgetPictureError = useSelector(addBudgetPictureErrorSelector)

  const error =
    updateBudgetError?.first() ||
    deleteBudgetItemError?.first() ||
    updateBudgetItemError?.first() ||
    deleteBudgetPictureError?.first() ||
    addBudgetPictureError?.first()

  const initialModal = useMemo(() => {
    return { isOpen: false, message: '', icon: '', type: 'info', action: () => {} }
  }, [])
  const [modal, setModal] = useState(initialModal)

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const [isEdit, onToggleEdit] = useToggle(false)
  const [isApproveBudgetDrawerOpen, onToggleApproveBudgetDrawerOpen] = useToggle()
  const [isNotAllowedToEditBudgetText, onToggleNotAllowedToEditBudgetText] = useToggle()
  const [isNotAllowedToEditFileBudget, onToggleNotAllowedToEditFileBudget] = useToggle()
  const [isCancelEditDialogOpen, onToggleCancelEditDialog] = useToggle()
  const [isBackToServiceOrder, onToggleBackToServiceOrder] = useToggle()
  const [isNotAllowToEditBudget, onToggleNotAllowToEditBudget] = useToggle()
  const [showDetailRefuse, toggleShowDatailRefuse] = useToggle()
  const [isSavingBudget, setSavingBudget] = useState({
    updateBudget: false,
    deleteBudgetItem: false,
    updateBudgetItem: false,
    deleteBudgetPicture: false,
    addBudgetPicture: false,
  })

  const [toastOpen, setToastOpen] = useState(false)
  const [toastInfo, setToastInfo] = useState({})
  const [updatingInstallments, setUpdatingInstallments] = useState(false)
  const { showToast } = useToast()

  const alert = useMemo(() => {
    const regimes = [budget?.budgetPayer?.[0]?.regime, budget?.tradesman?.companyTaxRegime]

    const hasRegimeWithoutTax = regime => TAX_REGIMES.WITHOUT_TAX.includes(regime)

    if (
      !regimes.some(hasRegimeWithoutTax) &&
      budget?.withholdingTax <= 0 &&
      serviceOrder?.stage === SERVICE_ORDER_STATUS.FINISHED
    ) {
      return {
        isOpen: true,
        title: 'O prestador não inseriu o valor do imposto retido',
        severity: 'warning',
      }
    }

    return { isOpen: false, title: '', severity: '' }
  }, [
    budget?.budgetPayer?.[0]?.regime,
    budget?.tradesman?.companyTaxRegime,
    budget?.withholdingTax,
    serviceOrder?.stage,
  ])

  const REASON_OPTIONS = {
    customer_does_not_want_to_do: 'O cliente não quer fazer',
    customer_will_do_on_their_own: 'O cliente vai fazer por conta própria',
    customer_did_not_like_payment_terms: 'O cliente não gostou das condições de pagamento',
    customer_did_not_like_solution: 'O cliente não gostou da solução',
    customer_did_not_like_price: 'O cliente não gostou do preço',
    another_reason: 'Outro motivo',
    account_was_deleted: 'Prestador vinculado excluiu a conta',
  }
  const reasonForRefusal = useMemo(
    () => (budget?.reasonForRefusal ? REASON_OPTIONS[budget.reasonForRefusal] : ''),
    [budget?.reasonForRefusal]
  )
  const isSavingLoading = Object.values(isSavingBudget).some(value => !!value)
  const wasSavingLoading = usePrevious(isSavingLoading)

  const isOriginal = useMemo(() => budget?.isOriginal, [budget?.isOriginal])
  const budgetPayer = useMemo(() => budget?.budgetPayer?.[0], [budget?.budgetPayer])
  const isAlertShowing = useMemo(() => Boolean(budget?.refused), [budget?.refused])

  const handleGetIsActive = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const isEditBlocked = isManager || isApprover || !isAdmin
  const hasAnotherAvailableBudget = useMemo(
    () =>
      serviceOrder?.budget?.some(
        item =>
          item !== undefined &&
          !item.get('isOriginal') &&
          item.get('id') !== Number(budgetId) &&
          !item.get('refused') &&
          !item.get('approved')
      ),
    [budgetId, serviceOrder?.budget]
  )

  const onSetInitialBudgetState = useCallback(() => {
    const payload = {
      diagnosis: budget.diagnosis || '',
      proposedSolution: budget.proposedSolution || '',
      budgetItems: budget.budgetItems || [],
      acceptsInstallment: budget.acceptsInstallment || false,
      installments: budget.installments || '',
      acceptsWarranty: budget.acceptsWarranty || false,
      warrantyMonths: budget.warrantyMonths || '',
      visitHappened: budget.visitHappened || false,
      totalPriceTradesman: budget.totalPriceTradesman || '',
      totalPriceServiceProvider: budget.totalPriceServiceProvider || 0,
      totalPrice: budget.priceTotal || 0,
      liquidValue: budget.liquidValue || 0,
      referaCompletionPercentage: budget?.referaCompletionPercentage || 0,
      referaCompletionPercentageValue: budget?.priceRefera || 0,
      agencyCompletionPercentage: budget?.agencyCompletionPercentage || 0,
      agencyCompletionPercentageValue: budget?.priceAgency || 0,
      withholdingTax: budget?.withholdingTax || 0,
      withholdingTaxValue: budget?.withholdingTaxValue || 0,
      financialIncome: budget?.financialIncome || 0,
      originalFinancialIncome: budget?.financialIncome || 0,
      maxTaxAllowed: budget.maxTaxAllowed || 0,
      financialIncomeValue: budget?.financialIncomeValue || 0,
      budgetPictures: budget.budgetPictures || [],
      personPaying: budget.personPaying,
      personPayingEmail: budget.personPayingEmail || '',
      personPayingWhatsapp: budget.personPayingWhatsapp || undefined,
      createdAt: budget.createdAt || '',
      blockFinanceTransfers: budget?.blockFinanceTransfers || false,
    }
    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [budget])

  const showSuccessToast = () => showToast({ type: 'success' })

  useFetchCall(REFUSE_BUDGET.ACTION, showSuccessToast)
  useFetchCall(GET_BUDGET.ACTION, onSetInitialBudgetState)

  const onUpdateBudgetSuccessful = useCallback(() => {
    setSavingBudget(prevState => ({
      ...prevState,
      updateBudget: false,
      deleteBudgetItem: false,
      updateBudgetItem: false,
      deleteBudgetPicture: false,
      addBudgetPicture: false,
    }))

    handleGetIsActive()
    // FIXME: LEMBRETE: ISSO AQUI ESTÁ BUGANDO DEVIDO AO UNDEFINED NA PROMISE,
    // ESSA FUNÇÃO FOI CRIADA PARA PERMITIR QUE O LOADING FECHE NO MOMENTO
    // DE SALVAR O BUDGET. PARTE DO CÓDIGO FOI COMENTADO POIS IMPEDIA A ATUALIZAÇÃO
    // DO STATUS. A PARTE NÃO COMENTADA AINDA É NECESSÁRIA ATÉ QUE O UNDEFINED NO
    // RETORNO DAS ACTIONS SEJA RESOLVIDO, DO CONTRÁRIO NENHUM LOADING ACABA.

    // ESSE LEMBRETE É PORQUE VAMOS VOLTAR AQUI DEPOIS COM CERTEZA. FAVOR NÃO APAGAR ATÉ O
    // UNDEFINED SER RESOLVIDO.

    // const payload = {
    //   budgetItems: budget?.budgetItems.toJS(),
    // }

    // localDispatch({
    //   type: UPDATE_STATE,
    //   payload,
    // })
  }, [
    handleGetIsActive,
    /* budget?.budgetItems */
  ])

  const onDeleteBudgetItemsSuccessful = useCallback(() => {
    let payload = {
      budgetItems: budget?.budgetItems,
    }
    if (state.budgetItemsToRemove) {
      payload = {
        ...payload,
        budgetItemsToRemove: [],
      }
    }

    setSavingBudget(prevState => ({
      ...prevState,
      deleteBudgetItem: false,
    }))

    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [budget?.budgetItems, state.budgetItemsToRemove])

  const onDeleteBudgetItemsRejected = useCallback(() => {
    setSavingBudget(prevState => ({
      ...prevState,
      deleteBudgetItem: false,
    }))
  }, [])

  useFetchCall(
    DELETE_BUDGET_ITEM.ACTION,
    onDeleteBudgetItemsSuccessful,
    onDeleteBudgetItemsRejected
  )

  const onEditBudgetItemsSuccessful = useCallback(() => {
    let payload = {
      budgetItems: budget?.budgetItems,
    }
    if (state.budgetItemsToEdit) {
      payload = {
        ...payload,
        budgetItemsToEdit: [],
      }
    }

    setSavingBudget(prevState => ({
      ...prevState,
      updateBudgetItem: false,
    }))

    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [budget?.budgetItems, state.budgetItemsToEdit])

  const onEditBudgetItemsRejected = useCallback(() => {
    setSavingBudget(prevState => ({
      ...prevState,
      updateBudgetItem: false,
    }))
  }, [])

  useFetchCall(UPDATE_BUDGET_ITEM.ACTION, onEditBudgetItemsSuccessful, onEditBudgetItemsRejected)

  const onAddBudgetPictureSuccessful = useCallback(() => {
    // const payload = {
    //   budgetPictures: budget?.budgetPictures.toJS(),
    //   budgetPicturesToAdd: [],
    // }

    setSavingBudget(prevState => ({
      ...prevState,
      addBudgetPicture: false,
    }))

    localDispatch({
      type: UPDATE_STATE,
      payload: { budgetPicturesToAdd: [] },
    })
  }, [state])

  const onDeleteBudgetPictureSuccessful = useCallback(() => {
    setSavingBudget(prevState => ({
      ...prevState,
      deleteBudgetPicture: false,
    }))

    localDispatch({
      type: UPDATE_STATE,
      payload: { budgetPicturesToRemove: [] },
    })
  }, [state])

  const onBackToServiceOrderClick = useCallback(() => {
    onToggleCancelEditDialog()

    if (!isBackToServiceOrder) {
      onToggleBackToServiceOrder()
    }
  }, [isBackToServiceOrder, onToggleBackToServiceOrder, onToggleCancelEditDialog])

  const onCloseCancelEditBudgetDialog = useCallback(() => {
    onToggleCancelEditDialog()

    if (isBackToServiceOrder) {
      onToggleBackToServiceOrder()
    }
  }, [isBackToServiceOrder, onToggleBackToServiceOrder, onToggleCancelEditDialog])

  // TODO: Remove this dispatch once the BE is sending the step_status info when updating the budget
  const refetchServiceOrder = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  useFetchCall(APPROVE_BUDGET.ACTION, () => {
    onToggleApproveBudgetDrawerOpen()
    updatedStateAndLog()
  })

  useFetchCall(CANCEL_APPROVE_BUDGET.ACTION, () => {
    updatedStateAndLog()
    refetchServiceOrder()
  })

  useFetchCall(UPDATE_BUDGET.ACTION, refetchServiceOrder)
  useFetchCall(UNREFUSE_BUDGET.ACTION, refetchServiceOrder)
  useFetchCall(RETURN_BUDGET_PROVIDER.ACTION, refetchServiceOrder)
  useFetchCall(REFUSE_BUDGET.ACTION, refetchServiceOrder)
  useFetchCall(GET_BUDGET.ACTION, refetchServiceOrder)

  const renderPapersPayerData = useMemo(
    () => [
      [
        {
          id: 1,
          label: 'Dados do prestador',
          value: [
            {
              value: `
                  ${budget?.tradesman?.companyName || ''}
                  ${`, ${phoneNumberMask(budget?.tradesman?.phone)}` || ''}
                  `,
              name: 'providerData',
            },
          ],
        },
      ],
      [
        {
          id: 2,
          label: 'Data do recebimento do orçamento',
          value: [
            {
              value: formatDate(budget?.createdAt),
              name: 'budgetCreatedAt',
            },
          ],
        },
      ],
    ],
    [budget, state.diagnosis, state.proposedSolution]
  )
  const renderPapersSolutionsData = useMemo(
    () => [
      [
        {
          id: 3,
          label: 'Diagnóstico do problema',
          value: [{ value: state.diagnosis, name: 'diagnosis' }],
        },
      ],
      [
        {
          id: 4,
          label: 'Solução do problema',
          value: [{ value: state.proposedSolution, name: 'proposedSolution' }],
        },
      ],
    ],
    [budget, state.diagnosis, state.proposedSolution]
  )

  const updatedStateAndLog = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
  }, [dispatch, serviceOrderId])

  const handleCloseModal = useCallback(() => {
    setModal(old => ({ ...old, isOpen: false }))
  }, [setModal, initialModal])

  const handleVerifyPaidInstallment = async () => {
    let cannotEdit = false

    await Promise.resolve(dispatch(verifyPaidInstallment(serviceOrderId, budgetId))).catch(err => {
      setModal({
        isOpen: true,
        message: err || 'Ocorreu um erro ao verificar o parcelamento.',
        icon: DangerIcon,
        type: 'info',
        action: handleCloseModal,
      })
      cannotEdit = true
    })
    return cannotEdit
  }

  const onEditBudgetClick = useCallback(async () => {
    if (budget.refused) {
      onToggleNotAllowToEditBudget()
      return
    }
    if (!isEdit && budget?.approved && !isAdmin) {
      onToggleNotAllowedToEditBudgetText()
    }
    if (budgetPayer && (await handleVerifyPaidInstallment())) {
      return
    }
    onToggleEdit()
  }, [
    isEdit,
    setModal,
    budgetPayer,
    onToggleEdit,
    budget?.refused,
    budget?.approved,
    handleVerifyPaidInstallment,
    onToggleNotAllowToEditBudget,
    onToggleNotAllowedToEditBudgetText,
  ])

  const onFieldChange = useCallback(event => {
    const { name, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: { [name]: value },
    })
  }, [])

  const onRadioChange = useCallback(event => {
    const { name, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: { [name]: value === 'true' },
    })
  }, [])

  const onSwitchChange = useCallback(event => {
    const { name, checked } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: { [name]: checked },
    })
  }, [])

  const handleBudgetItemsChange = useCallback(event => {
    const { name, value, dataset } = event.target
    const elementIndex = Number(dataset.id)

    localDispatch({
      type: UPDATE_BUDGET_ITEMS,
      elementIndex,
      payload: { [name]: value },
    })
  }, [])

  const onAddBudgetItem = useCallback(() => {
    localDispatch({
      type: ADD_BUDGET_ITEM,
    })
  }, [])

  const onRemoveBudgetItem = useCallback(itemToDelete => {
    localDispatch({
      type: REMOVE_BUDGET_ITEM,
      payload: { itemIndex: itemToDelete?.index, itemId: itemToDelete?.id },
    })
  }, [])

  const onAddPicture = useCallback(picture => {
    localDispatch({
      type: ADD_BUDGET_PICTURES,
      payload: { picture },
    })
  }, [])

  const onRemovePicture = useCallback(attachmentIndex => {
    localDispatch({
      type: REMOVE_BUDGET_PICTURES,
      payload: {
        attachmentIndex,
      },
    })
  }, [])

  const onCancelEditClick = useCallback(() => {
    dispatch(getBudget(serviceOrderId, budgetId))
    onSetInitialBudgetState()
    onToggleEdit()
    onToggleCancelEditDialog()

    if (isBackToServiceOrder) {
      onToggleBackToServiceOrder()
      navigate(`/chamado/${serviceOrderId}`)
    }
  }, [
    isBackToServiceOrder,
    onSetInitialBudgetState,
    onToggleBackToServiceOrder,
    onToggleCancelEditDialog,
    onToggleEdit,
    serviceOrderId,
  ])

  const verifyAndFormatFinanceItensPayload = payload => {
    const formattedPayload = { ...payload }

    Object.keys(formattedPayload)?.forEach(payloadItem => {
      if (FINANCE_PAYLOAD_KEYS_TO_UPDATE_BUDGET.includes(payloadItem)) {
        formattedPayload[payloadItem] = formatNumber(state[payloadItem])
      }
    })

    return formattedPayload
  }

  const onSaveBudget = useCallback(async () => {
    let payload = {}
    let willDispatch = false

    Object.keys(state).map(key => {
      const keysToRemove = [
        'budgetItemsToRemove',
        'budgetItemsToEdit',
        'budgetItems',
        'budgetPicturesToRemove',
        'budgetPicturesToAdd',
        'budgetPictures',
        'originalFinancialIncome',
        'personPaying',
        'personPayingWhatsapp',
      ]

      const shouldAddOnPayload =
        (!keysToRemove.includes(key) &&
          state[key] !== budget[key] &&
          !(typeof budget[key] === 'undefined' && state[key] === '')) ||
        FINANCE_PAYLOAD_KEYS_TO_UPDATE_BUDGET.includes(key)

      if (shouldAddOnPayload) {
        if ((key === 'installments' || key === 'warrantyMonths') && state[key] === '') {
          payload[key] = 0
        } else {
          payload[key] = state[key]
        }
      }
      return null
    })

    if (state?.blockFinanceTransfers !== budget?.blockFinanceTransfers) {
      payload.blockFinanceTransfers = state.blockFinanceTransfers
    } else {
      delete payload?.blockFinanceTransfers
    }

    // const items = budget?.budgetItems.toJS().map(x => {
    //   return { id: x.id, description: x.description, price: x.price }
    // })

    payload.budgetItems = state.budgetItems

    payload = verifyAndFormatFinanceItensPayload(payload)

    if (await handleVerifyPaidInstallment()) return

    if (Object.values(payload).length) {
      setSavingBudget(prevState => ({
        ...prevState,
        updateBudget: true,
      }))

      if (
        payload.agencyCompletionPercentage ||
        payload.referaCompletionPercentage ||
        payload.withholdingTax
      ) {
        setUpdatingInstallments(true)
      }

      willDispatch = true
      await dispatch(updateBudget(serviceOrderId, budgetId, payload))
        .then(onUpdateBudgetSuccessful)
        .catch(updateError => {
          console.warn('Error: ', updateError?.error_code)
          willDispatch = false
          setToastOpen(true)
          setToastInfo({
            severity: 'error',
            title: 'Erro',
            message: updateError?.error_code || 'Ocorreu um erro ao realizar a operação',
          })
          setSavingBudget(prevState => ({
            ...prevState,
            updateBudget: false,
          }))
        })
    }

    if (state.budgetPicturesToRemove?.length) {
      setSavingBudget(prevState => ({
        ...prevState,
        deleteBudgetPicture: true,
      }))
      dispatch(
        deleteBudgetPictures(serviceOrderId, budgetId, {
          ids: state.budgetPicturesToRemove,
        })
      ).then(onDeleteBudgetPictureSuccessful)
    }

    if (state.budgetPicturesToAdd?.length) {
      setSavingBudget(prevState => ({
        ...prevState,
        addBudgetPicture: true,
      }))

      const picturePayload = {
        budgetPictures: state.budgetPicturesToAdd,
      }
      // eslint-disable-next-line consistent-return
      picturePayload?.budgetPictures.forEach(async picture => {
        await Promise.resolve(dispatch(addBudgetPicture(serviceOrderId, budgetId, picture))).then(
          onAddBudgetPictureSuccessful()
        )
      })
      willDispatch = true
    }

    if (willDispatch) {
      onToggleEdit()
      updatedStateAndLog()
    }
  }, [
    budget,
    budgetId,
    dispatch,
    onToggleEdit,
    serviceOrderId,
    state,
    onSetInitialBudgetState,
    setToastOpen,
    setToastInfo,
    onToggleNotAllowedToEditFileBudget,
    updatedStateAndLog,
    setSavingBudget,
  ])

  const isDelayedActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DELAYED,
  })

  useEffect(() => {
    dispatch(getBudget(serviceOrderId, budgetId))
  }, [serviceOrderId, budgetId])

  useEffect(() => {
    const confirmExit = () => {
      if (isEdit) {
        return 'Salve antes as suas alterações no registro.'
      }

      return null
    }

    window.onbeforeunload = confirmExit
  }, [isEdit])

  if (isServicesLoading || isBudgetLoading || !budget || !Object.keys(budget).length) {
    return <Loading className={styles.loader} />
  }

  return (
    <Grid className={styles.container}>
      {(isSavingLoading || !budget) && <Loader hasBackdrop open={isSavingLoading || !budget} />}

      {alert.isOpen && (
        <ReferaAlert severity={alert.severity}>
          <AlertTitle>{alert.title}</AlertTitle>
        </ReferaAlert>
      )}

      {!budget?.refused && isDelayedActive && (
        <Grid item xs={12} className={styles.actionDeadlineWrapper}>
          <ActionDeadline handleShowHelpRequest={handleShowHelpRequest} />
        </Grid>
      )}

      <Actions
        serviceOrderId={serviceOrder.id}
        onToggleEdit={onEditBudgetClick}
        isEdit={isEdit}
        onCancelEditClick={onToggleCancelEditDialog}
        onSaveClick={onSaveBudget}
        isOriginalBudget={isOriginal}
        onBackToServiceOrderClick={onBackToServiceOrderClick}
      />

      {isAdmin && budget?.tradesman && (
        <Grid className={styles.switchContainer}>
          <Typography>Bloquear repasses financeiros</Typography>
          <SwitchComponent
            label="Bloquear repasses financeiros"
            checked={!!state?.blockFinanceTransfers || false}
            name="blockFinanceTransfers"
            onChange={onSwitchChange}
            disabled={!isEdit}
          />
        </Grid>
      )}

      {isAlertShowing && (
        <>
          <Alert
            severity="warning"
            color="error"
            variant="standard"
            className={styles.alertBackground}
            icon={<WarningIcon fontSize="large" color="error" />}
            sx={{
              borderBottom: '4px groove red',
              borderEndEndRadius: 0,
              borderEndStartRadius: 0,
              marginBottom: '15px',
            }}
          >
            <AlertTitle className={styles.alertTitle}>Orçamento recusado</AlertTitle>
            <p style={{ color: '#696969', fontSize: '16px', fontFamily: 'Roboto' }}>
              O orçamento foi recusado pelo seguinte motivo: {reasonForRefusal}
            </p>
            <br />
            <Button
              style={{
                textDecoration: 'none',
                color: '#1671ED',
                border: 'none',
                background: 'none',
                padding: '0px',
              }}
              onClick={toggleShowDatailRefuse}
            >
              Ver mais detalhes
            </Button>
          </Alert>
        </>
      )}
      <Grid className={styles.mainInfo}>
        <Grid className={styles.paperWrapper}>
          {renderPapersPayerData.map(options => {
            if (budget?.proposeOpened && !budget?.tradesman && options[0]?.id === 1) {
              return (
                <Paper variant="outlined" className={styles.proposeOpened} key={options[0]?.id}>
                  <Typography>Orçamento interno</Typography>
                </Paper>
              )
            }
            return (
              <PaperInformation
                key={options[0].id}
                options={options}
                className={styles[`paper${options[0].id}`]}
                isEdit={options[0].id !== 1 && isEdit && !isEditBlocked}
                onChange={onFieldChange}
              />
            )
          })}
        </Grid>
        <Grid className={styles.content}>
          <Typography className={styles.cardTitle}>Descrição do chamado</Typography>
          <Grid className={styles.card}>
            <Textfield
              value={serviceOrder?.description}
              multiline
              InputProps={{
                disableUnderline: true,
                className: styles.textField,
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid className={styles.paperWrapper}>
          {renderPapersSolutionsData.map(options => {
            return (
              <PaperInformation
                key={options[0].id}
                options={options}
                className={styles[`paper${options[0].id}`]}
                isEdit={options[0].id !== 1 && isEdit && !isEditBlocked}
                onChange={onFieldChange}
              />
            )
          })}
        </Grid>
        <Grid className={styles.descriptionWrapper}>
          <Typography variant="h5" component="p" className={styles.descriptionTitle}>
            Orçamento detalhado
          </Typography>
          <Details
            serviceOrderId={serviceOrderId}
            budgetId={budgetId}
            orderDetails={serviceOrder?.orderDetails}
            budget={state}
            budgetData={budget}
            isOriginalBudget={isOriginal}
            isEdit={isEdit}
            handleInputChange={onFieldChange}
            onRadioChange={onRadioChange}
            handleBudgetItemsChange={handleBudgetItemsChange}
            onAddBudgetItem={onAddBudgetItem}
            onRemoveBudgetItem={onRemoveBudgetItem}
            isEditBlocked={isEditBlocked}
            errors={updateBudgetError}
            onAddPicture={onAddPicture}
            onRemovePicture={onRemovePicture}
          />
        </Grid>
        <Toast
          draggable
          open={toastOpen}
          autoHideDuration={6000}
          onClose={() => {
            setToastOpen(false)
            setToastInfo({})
          }}
        >
          <ReferaAlert
            severity={toastInfo.severity}
            onClose={() => {
              setToastOpen(false)
              setToastInfo({})
            }}
          >
            <AlertTitle>{toastInfo.title}</AlertTitle>
            {toastInfo.message}
          </ReferaAlert>
        </Toast>
      </Grid>
      <BudgetButtons isEdit={isEdit} />
      {isCancelEditDialogOpen && (
        <CancelEditBudgetDialog
          isOpen
          onClose={onCloseCancelEditBudgetDialog}
          onCancelEdit={onCancelEditClick}
        />
      )}
      <ApproveBudgetDrawer
        isOpen={isApproveBudgetDrawerOpen}
        onClose={onToggleApproveBudgetDrawerOpen}
        budget={budget}
        serviceOrderId={serviceOrderId}
      />
      {isNotAllowedToEditBudgetText && (
        <UnavailableActionBudgetModal
          title="Aprovar orçamento"
          text={notAllowedToEditBudgetText}
          handleModal={onToggleNotAllowedToEditBudgetText}
          handleConfirm={onToggleNotAllowedToEditBudgetText}
        />
      )}
      {isNotAllowedToEditFileBudget && (
        <UnavailableActionBudgetModal
          text={notAllowedToEditFileBudget}
          handleModal={onToggleNotAllowedToEditFileBudget}
          handleConfirm={onToggleNotAllowedToEditFileBudget}
        />
      )}
      {isNotAllowToEditBudget && (
        <UnavailableActionBudgetModal
          text={NOT_ALLOW_EDIT_BUDGET_REFUSED}
          handleModal={onToggleNotAllowToEditBudget}
          handleConfirm={onToggleNotAllowToEditBudget}
        />
      )}

      {showDetailRefuse && (
        <ShowMoreDetailRefuseModal
          handleModal={toggleShowDatailRefuse}
          refusalMoreDetails={budget?.refusalMoreDetails}
        />
      )}
      <Dialog
        open={modal?.isOpen}
        icon={modal?.icon}
        type={modal?.type}
        subject={modal?.message}
        onApprove={modal?.action}
      />
      <Snackbar
        action={[
          !updatingInstallments && UPDATE_BUDGET.ACTION,
          DELETE_BUDGET_ITEM.ACTION,
          UPDATE_BUDGET_ITEM.ACTION,
          ADD_BUDGET_PICTURE.ACTION,
          DELETE_BUDGET_PICTURE.ACTION,
          UNREFUSE_BUDGET.ACTION,
          SENT_BUDGET_COUNTER_PROPOSAL.ACTION,
          APPROVE_BUDGET.ACTION,
          TRIGGER_SNACKBAR.ACTION,
          CANCEL_APPROVE_BUDGET.ACTION,
        ]}
      />
    </Grid>
  )
}

export default React.memo(EstimationStep)

import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  Drawer,
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  OutlinedInput,
} from '@material-ui/core'
import { useParams } from '@reach/router'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import Inputmask from 'inputmask'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'immutable'

import RejectIcon from '_assets/icons/ic-reject.svg'
import CloseIcon from '_assets/icons/ic-close.svg'
import Svg from '_components/svg'
import Select from '_components/select'
import Textfield from '_components/textfield'
import Button from '_components/button'
import useForm from '_hooks/use-form'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-toggle'
import { refuseBudget, REFUSE_BUDGET, getReasonsToRefusal } from '_modules/budget/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import { getBudgetByIdSelector, getReasonsToRefuseSelector } from '_modules/budget/selectors'

import { REASON_TO_REFUSE_BUDGET as REASON_OPTIONS } from '_utils/constants/budget'

import useStyles from './styles'
import SelectProviderDialog from './select-provider-dialog'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'

import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const CANCELLATION_OPTIONS = {
  YES: 'yes',
  NO: 'no',
}

const DO_CANCEL_OPTIONS = [
  { value: CANCELLATION_OPTIONS.YES, label: 'Sim' },
  { value: CANCELLATION_OPTIONS.NO, label: 'Não' },
]

const INITIAL_STATE = {
  reasonForRefusal: '',
  anotherReasonForRefusal: '',
  comfortableAmount: '',
  cancelServiceOrder: false,
  refusalMoreDetails: '',
  serviceOrderCancelDetails: '',
  alsoCancelServiceOrder: '',
  reasonForCancelServiceOrder: '',
}

const RejectBudgetDrawer = ({ className, isOpen, onClose, cancelReasons, isRevised }) => {
  const styles = useStyles()
  const { serviceOrderId, budgetId } = useParams()
  const reasonToRefusal = useSelector(getReasonsToRefuseSelector('external_budget'))
  const priceRef = useRef(null)
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const budget = useSelector(getBudgetByIdSelector(budgetId))

  const {
    values: {
      reasonForRefusal,
      anotherReasonForRefusal,
      comfortableAmount,
      cancelServiceOrder,
      refusalMoreDetails,
      serviceOrderCancelDetails,
      alsoCancelServiceOrder,
      reasonForCancelServiceOrder,
    },
    handleInputChange,
    resetValues,
  } = useForm(INITIAL_STATE)
  const [isProviderDialogOpen, onToggleProviderDialog] = useToggle()

  useEffect(() => {
    if (reasonForRefusal === REASON_OPTIONS.PRICE) {
      Inputmask('9{1,4}.99', { numericInput: true, radixPoint: ',' }).mask(priceRef.current)
    }
  }, [reasonForRefusal])

  useEffect(() => {
    dispatch(getReasonsToRefusal())
  }, [])

  const formattedReasonsToRefuseList = useMemo(() => {
    if (reasonToRefusal.length) {
      return reasonToRefusal.map(option => ({ value: option.slug, label: option.reason }))
    }
    return []
  }, [reasonToRefusal])

  const initialStateOfErrors = {
    refusalMoreDetails: false,
    serviceOrderCancelDetails: false,
    alsoCancelServiceOrder: false,
    reasonForCancelServiceOrder: false,
    reasonForRefusal: false,
    anotherReasonForRefusal: false,
    comfortableAmount: false,
  }

  const [error, setError] = useState(initialStateOfErrors)

  const errorMessage = 'Campo obrigatório'

  const onSendClick = useCallback(() => {
    const checkFields = [{ refusalMoreDetails }, { alsoCancelServiceOrder }, { reasonForRefusal }]

    if (alsoCancelServiceOrder === 'yes') {
      if (
        checkIfUserDoesNotPermission(
          PERMISSIONS.CANCEL_REACTIVATE_SERVICE_ORDER,
          PERMISSIONS_ACTIONS.ADD,
          'Você indicou que também quer cancelar o chamado, mas você não tem permissão para isto.'
        )
      ) {
        return null
      }
      checkFields.push({ serviceOrderCancelDetails }, { reasonForCancelServiceOrder })
      if (reasonForRefusal === REASON_OPTIONS.PRICE) checkFields.push({ comfortableAmount })
      if (reasonForRefusal === REASON_OPTIONS.OTHER) checkFields.push({ anotherReasonForRefusal })
    }

    const fieldsError = checkFields.filter(list =>
      Object.values(list).some(field => field === null || field === '' || field === 'undefined')
    )

    const changeFieldsError = fieldsError.reduce(
      (errors, fieldName) => ({ ...errors, [Object.keys(fieldName)]: true }),
      {}
    )

    if (fieldsError.length > 0) {
      return setError(oldFields => ({ ...oldFields, ...changeFieldsError }))
    }

    const payload = {
      reasonForRefusal,
      cancelServiceOrder: false,
      refusalMoreDetails,
      serviceOrderCancelDetails,
      alsoCancelServiceOrder,
      reasonForCancelServiceOrder,
      ignoreRevision: !isRevised,
    }

    if (reasonForRefusal === REASON_OPTIONS.OTHER) {
      payload.anotherReasonForRefusal = anotherReasonForRefusal
    } else if (reasonForRefusal === REASON_OPTIONS.PRICE) {
      payload.comfortableAmount = Number(comfortableAmount)
    }

    if (alsoCancelServiceOrder === 'yes') {
      payload.cancelServiceOrder = true
      payload.serviceOrderCancelDetails = serviceOrderCancelDetails
    } else if (alsoCancelServiceOrder === 'no') {
      payload.cancelServiceOrder = false
    }

    payload.refusalMoreDetails = refusalMoreDetails
    payload.alsoCancelServiceOrder = alsoCancelServiceOrder
    payload.reasonForCancelServiceOrder = reasonForCancelServiceOrder

    dispatch(
      refuseBudget({
        serviceOrderId,
        budgetId,
        payload,
      })
    ).catch(err => {
      const message = formatErrorMessage(err)
      showToast({ type: 'error', message })
    })
    return resetValues()
  }, [
    anotherReasonForRefusal,
    budgetId,
    cancelServiceOrder,
    comfortableAmount,
    dispatch,
    reasonForRefusal,
    serviceOrderId,
    refusalMoreDetails,
    serviceOrderCancelDetails,
    alsoCancelServiceOrder,
    reasonForCancelServiceOrder,
    isRevised,
  ])

  useFetchCall(REFUSE_BUDGET.ACTION, () => {
    dispatch(getHistoryLogs(serviceOrderId))
  })

  const onRefuseSuccess = useCallback(() => {
    onClose()
    setError(initialStateOfErrors)
    // handleAlert(reasonForAlert)
  }, [
    budget.variationAccepted,
    cancelServiceOrder,
    onClose,
    onToggleProviderDialog,
    reasonForRefusal,
    refusalMoreDetails,
    serviceOrderCancelDetails,
    alsoCancelServiceOrder,
    reasonForCancelServiceOrder,
  ])

  const onCloseProviderDialog = useCallback(() => {
    onToggleProviderDialog()
    setError(initialStateOfErrors)
  }, [onToggleProviderDialog])

  const [isRefusingBudget] = useFetchCall(REFUSE_BUDGET.ACTION, onRefuseSuccess)
  const onSendCounterProposalSuccess = useCallback(() => {
    if (!budget.requestedChangeServiceProvider) {
      onToggleProviderDialog()
      return
    }
    onToggleProviderDialog()
    onClose()
  }, [budget.requestedChangeServiceProvider, onClose, onToggleProviderDialog])

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      className={classnames(styles.container, className)}
    >
      <Box className={styles.box}>
        <Grid component="header" className={styles.header}>
          <Svg icon={RejectIcon} className={styles.rejectIcon} />
          <Typography className={styles.title} typography="h4" component="h1">
            Recusar orçamento ID #{budgetId}
          </Typography>
          <IconButton onClick={onClose} aria-label="Fechar menu">
            <Svg icon={CloseIcon} className={styles.close} />
          </IconButton>
        </Grid>
        <Divider className={styles.divider} />
        <>
          <Grid className={styles.content}>
            <Select
              IconComponent={ExpandMoreIcon}
              label="Selecione o motivo"
              options={formattedReasonsToRefuseList}
              onChange={handleInputChange}
              name="reasonForRefusal"
              value={reasonForRefusal}
              emptyValue=""
              defaultValue=""
              className={styles.select}
              error={error.reasonForRefusal && !reasonForRefusal}
            />
            {error.reasonForRefusal && !reasonForRefusal && (
              <Typography className={styles.errorText}>{errorMessage}</Typography>
            )}
            {reasonForRefusal === REASON_OPTIONS.OTHER && (
              <Textfield
                value={anotherReasonForRefusal}
                onChange={handleInputChange}
                name="anotherReasonForRefusal"
                label="Qual?"
                style={{ display: 'none' }}
                className={styles.input}
              />
            )}
            {reasonForRefusal === REASON_OPTIONS.PRICE && (
              <Textfield
                value={comfortableAmount}
                onChange={handleInputChange}
                name="comfortableAmount"
                label="Qual valor você estaria confortável em pagar?"
                className={styles.input}
                inputRef={priceRef}
                InputProps={{
                  startAdornment: 'R$ ',
                }}
              />
            )}
            <Typography className={styles.textTitle}>Mais detalhes</Typography>
            <OutlinedInput
              multiline
              className={styles.textInputN}
              placeholder="Mais detalhes."
              name="refusalMoreDetails"
              value={refusalMoreDetails}
              onChange={handleInputChange}
              required
              error={error.refusalMoreDetails && !refusalMoreDetails}
            />
            {error.refusalMoreDetails && !refusalMoreDetails && (
              <Typography className={styles.errorText}>{errorMessage}</Typography>
            )}
            <ConfidentialDataWarning style={{ marginTop: 8 }} />
            <Select
              IconComponent={ExpandMoreIcon}
              label="Deseja cancelar também o chamado?"
              options={DO_CANCEL_OPTIONS}
              onChange={handleInputChange}
              name="alsoCancelServiceOrder"
              value={alsoCancelServiceOrder}
              emptyValue=""
              defaultValue=""
              className={styles.select}
              required
              error={error.alsoCancelServiceOrder && !alsoCancelServiceOrder}
            />

            {error.alsoCancelServiceOrder && !alsoCancelServiceOrder && (
              <Typography className={styles.errorText}>{errorMessage}</Typography>
            )}
            {alsoCancelServiceOrder === 'yes' && (
              <>
                <Typography className={styles.textTitle}>
                  <Alert severity="warning">
                    <b>Atenção</b>
                    <br />
                    Ao responder Sim no campo Deseja cancelar também o chamado, a solicitação do
                    serviço de manutenção será cancelada e não haverá mais solicitação de novos
                    orçamentos a outros prestadores.
                  </Alert>
                </Typography>

                <Select
                  label="Selecione o motivo do cancelamento"
                  options={cancelReasons?.toArray()}
                  name="reasonForCancelServiceOrder"
                  value={reasonForCancelServiceOrder}
                  onChange={handleInputChange}
                  required
                  error={error.reasonForCancelServiceOrder && !reasonForCancelServiceOrder}
                />
                {error.reasonForCancelServiceOrder && !reasonForCancelServiceOrder && (
                  <Typography className={styles.errorText}>{errorMessage}</Typography>
                )}
                <Typography className={styles.textTitle}>Mais detalhes do cancelamento</Typography>
                <OutlinedInput
                  multiline
                  inputProps={{ maxLength: 1000 }}
                  className={styles.textInput}
                  placeholder="Mais detalhes."
                  name="serviceOrderCancelDetails"
                  value={serviceOrderCancelDetails}
                  onChange={handleInputChange}
                  required
                  error={error.serviceOrderCancelDetails && !serviceOrderCancelDetails}
                />
                {error.serviceOrderCancelDetails && !serviceOrderCancelDetails && (
                  <Typography className={styles.errorText}>{errorMessage}</Typography>
                )}
                <ConfidentialDataWarning style={{ marginTop: 8 }} />
              </>
            )}
            {alsoCancelServiceOrder === 'no' && (
              <>
                <Typography className={styles.textTitle}>
                  <Alert severity="warning">
                    <b>Atenção</b>
                    <br />
                    Ao responder Não no campo Deseja cancelar também o chamado, a Refera
                    providenciará um novo orçamento a um novo prestador.
                  </Alert>
                </Typography>
              </>
            )}
          </Grid>
          <Grid className={styles.action}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSendClick}
              isLoading={isRefusingBudget}
            >
              Enviar
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
        </>
      </Box>
      {isProviderDialogOpen && (
        <SelectProviderDialog
          isOpen
          onClose={onCloseProviderDialog}
          onSearchForAnotherProvider={onClose}
          serviceOrderId={serviceOrderId}
          budgetId={budgetId}
          onSendCounterProposalSuccess={onSendCounterProposalSuccess}
        />
      )}
    </Drawer>
  )
}

RejectBudgetDrawer.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cancelReasons: PropTypes.instanceOf(List).isRequired,
}

RejectBudgetDrawer.defaultProps = {
  className: '',
  isOpen: true,
}

export default React.memo(RejectBudgetDrawer)

import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from '@reach/router'
import { Dialog } from '@refera/ui-web'
import { TickSquare as TickSquareIcon } from '@refera/ui-icons'

import {
  completeCanceledServiceOrder,
  COMPLETE_CANCELED_SERVICE_ORDER,
} from '_modules/service-orders/actions'
import { getHistoryLogs } from '_modules/history-logs/actions'
import useFetchCall from '_hooks/use-fetch-call'

const CompleteCanceledServiceOrderModal = ({ isOpen, handleClose }) => {
  const { serviceOrderId } = useParams()
  const dispatch = useDispatch()

  const handleApprove = useCallback(() => {
    dispatch(completeCanceledServiceOrder(serviceOrderId))
    handleClose()
  }, [dispatch, handleClose, serviceOrderId])

  const handleCancel = useCallback(() => {
    handleClose()
  }, [handleClose])

  const refetchHistoryLogs = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId))
  }, [dispatch, serviceOrderId])

  // TODO: Update store without refetching logs once the BE is sending this data
  useFetchCall(COMPLETE_CANCELED_SERVICE_ORDER.ACTION, refetchHistoryLogs)

  useEffect(() => {
    const handleKeydown = event => {
      if (isOpen && event.key === 'Enter') {
        handleApprove()
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [handleApprove, isOpen])

  return (
    <Dialog
      open={isOpen}
      icon={TickSquareIcon}
      type="info"
      subject="Você tem certeza que deseja executar esta ação?"
      labelApprove="Sim"
      labelCancel="Não"
      onApprove={handleApprove}
      onCancel={handleCancel}
    />
  )
}

export default CompleteCanceledServiceOrderModal

import React from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/modal/open-budget-notify'

import useStyles from './styles'

const OpenBudgetNotify = ({ handleModal }) => {
  const styles = useStyles()

  const renderTitle = 'Pedir outro orçamento'
  const renderContent =
    'Este chamado possui um Orçamento ativo. Você deve abri-lo e recusá-lo para, só depois, poder pedir outro orçamento.'

  return (
    <Modal
      handleModal={handleModal}
      title={renderTitle}
      className={styles.modal}
      content={renderContent}
    />
  )
}

OpenBudgetNotify.propTypes = {
  handleModal: PropTypes.func.isRequired,
}

export default React.memo(OpenBudgetNotify)
